<template>
  <div class="organize-team-edit">
    <el-form label-width="80px">
      <el-form-item label="名称" style="width: 90%;" required>
        <el-input v-model="form.name" />
      </el-form-item>
      <el-form-item label="组长">
        <el-select
          v-model="form.adminUserId"
          style="width: 90%;"
          placeholder="请选择"
          clearable
          filterable
        >
          <el-option
            v-for="item in teamUser"
            :key="item.adminUserId"
            :label="item.name"
            :value="item.adminUserId"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitLoading"
        >确 定</el-button
      >
    </div>
  </div>
</template>

<script>
import { editTeam } from '@/api/team'

export default {
  name: 'editTeam',
  props: {
    show: Boolean,
    teamUser: Array,
    team: Object
  },
  data () {
    return {
      form: {},
      submitLoading: false
    }
  },
  watch: {
    show: {
      handler (newVal) {
        if (newVal) {
          const { team, teamUser } = this
          const leader = teamUser.find(user => user.deptLeader === 1)
          this.form = {
            name: team.name,
            adminUserId: leader ? leader.adminUserId : null
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    handleClose () {
      this.form = {}
      this.$emit('close')
    },
    handleSubmit () {
      if (!this.form.name) {
        this.$message.warning('请输入团队名称')
        return false
      }
      this.submitLoading = true
      editTeam(this.team.id, this.form)
        .then(() => {
          this.$emit('refresh')
          this.handleClose()
          this.$message.success('设置成功')
        })
        .finally(() => {
          this.submitLoading = false
        })
    }
  }
}
</script>

<style scoped>
.dialog-footer {
  text-align: center;
  margin-top: 300px;
}
</style>
