<template>
  <!-- <el-dialog
      title="添加新用户"
      :visible.sync="show"
      width="500px"
      :before-close="handleClose"> -->
     <div class='organize-team-addUser'>
    <div style="height: 300px">
      <el-select v-model="selectUser" style="width: 95%;padding-left:50px" placeholder="请选择" filterable multiple>
        <el-option
            v-for="item in selectUserList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
        </el-option>
      </el-select>
    </div>
    <div class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" @click="handleUserAdd" :loading="submitLoading">确 定</el-button>
        </div>
        </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { addTeamUser } from '@/api/team'

export default {
  name: 'addUser',
  props: {
    show: Boolean,
    team: Object,
    teamUser: Array
  },
  data () {
    return {
      selectUser: [],
      submitLoading: false
    }
  },
  computed: {
    selectUserList () {
      const { userList, teamUser } = this
      return userList.filter(item => !teamUser.some(user => user.adminUserId === item.id))
    },
    ...mapGetters(['userList'])
  },
  methods: {
    handleClose () {
      this.selectUser = []
      this.$emit('close')
    },
    handleUserAdd () {
      if (this.selectUser.length) {
        this.submitLoading = true
        addTeamUser(this.team.id, this.selectUser).then(() => {
          this.$emit('refresh')
          this.handleClose()
          this.$message.success('添加成功')
        }).finally(() => { this.submitLoading = false })
      } else {
        this.$message.warning('请选择用户')
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.dialog-footer {
  margin-top: 40px;
  text-align: center;
}
</style>
