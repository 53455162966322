<template>
  <div class="organization-page ">
    <div class="side-cont border-radius-6 box-shadow-light-grey">
      <div class="nav-bar">
        <el-radio-group v-model="tab" size="medium" @change="handleTabChange">
          <el-radio-button label="team">团队</el-radio-button>
          <el-radio-button label="role">角色</el-radio-button>
        </el-radio-group>
      </div>
      <div class="bot-content" v-loading="loading">
        <el-tree
          v-if="tab === 'team'"
          :data="teamList"
          default-expand-all
          :props="treeProps"
          :highlight-current="true"
          :current-node-key="selectTeam ? selectTeam.id : null"
          :check-on-click-node="true"
          :expand-on-click-node="false"
          node-key="id"
          @node-click="handleTeamClick"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <i class="iconfont iconbumen1"></i>
            <span>{{ node.label }}（{{ data.totalMan || 0 }}人）</span>
          </span>
        </el-tree>
        <div v-if="tab === 'role'">
          <div v-if="roleList.length">
            <div
              class="role-item"
              v-for="item in roleList"
              :key="item.id"
              @click="selectRole = item"
              :class="{ active: selectRole.id === item.id }"
            >
              <span>{{ item.name }}</span>
            </div>
          </div>
          <p v-else class="empty-text">暂无角色</p>
        </div>
      </div>
    </div>
    <div class="right-content border-radius-6 box-shadow-light-grey">
      <team-detail
        v-if="tab === 'team'"
        :team-list="teamList"
        :select-team="selectTeam"
      />
      <role-detail
        v-if="tab === 'role'"
        :role-list="roleList"
        :select-role="selectRole"
        @refreshRoles='getRoles'
      />
    </div>
  </div>
</template>

<script>
import TeamDetail from './team'
import RoleDetail from './role'
import { getAllTeam } from '@/api/team'
import { getRoleList } from '@/api/role'

export default {
  name: 'organization',
  data () {
    return {
      tab: 'team',
      loading: false,
      teamList: [],
      selectTeam: null,
      roleList: [],
      selectRole: null,
      treeProps: {
        children: 'children',
        label: 'name'
      }
    }
  },
  beforeRouteEnter (to, form, next) {
    next(vm => vm.getTeam())
  },
  methods: {
    getRoles () {
      this.loading = true
      getRoleList()
        .then(res => {
          this.roleList = res
          this.selectRole = res[0]
        })
        .finally(() => {
          this.loading = false
        })
    },
    getTeam () {
      this.loading = true
      getAllTeam()
        .then(res => {
          this.teamList = res
          if (!this.selectTeam) {
            this.selectTeam = res[0]
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleTeamClick (node) {
      this.selectTeam = node
    },
    handleTabChange (tab) {
      if (tab === 'team') {
        this.getTeam()
      } else {
        this.getRoles()
      }
    }
  },
  components: {
    TeamDetail,
    RoleDetail
  }
}
</script>

<style lang="scss" scoped>
.role-item {
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  font-size: 14px;
  color: #666;
  cursor: pointer;
}

.role-item.active {
  color: #222;
  background-color: #e8f4f5;
}

.role-item:hover {
  background-color: #e8f4f5;
}

.empty-text {
  text-align: center;
  line-height: 50px;
  font-size: 14px;
  color: #999;
}

.organization-page {
  // overflow: hidden;
  display: flex;
  // height: calc(100vh - 176px);
  // margin-top: 10px;
}

.nav-bar {
  text-align: center;
  padding: 15px 0;
}

.bot-content {
  flex: 1;
  padding: 0 10px;
  width: 290px;
}

.side-cont {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
.custom-tree-node {
    font-size: 14px;
    color: #333;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    }

.custom-tree-node .iconfont {
  color: #1c9399;
  margin-right: 5px;
}

.right-content {
  margin-left: 16px;
  flex: 1;
  background-color: #fff;
  padding: 20px;
  width:800px; // !测试定值
}
</style>
