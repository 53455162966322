<template>
  <el-drawer
    title="权限设置"
    :visible.sync="show"
    size="450px"
    :before-close="closeDialog"
  >
    <el-tree
      v-loading="loading"
      element-loading-text="数据加载中"
      :default-expanded-keys="[1]"
      :data="authorityList"
      show-checkbox
      node-key="id"
      :check-strictly="isShow"
      ref="tree"
    >
    </el-tree>
    <div class="fix-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="updateSetting">保 存</el-button>
    </div>
  </el-drawer>
</template>

<script>
import { getAuth } from '@/api/auth'
import { getRoleAuth, setRoleAuth } from '@/api/role'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    role: Object
  },
  data () {
    return {
      isShow: true,
      loading: false,
      authorityList: []
    }
  },
  watch: {
    show (newVal) {
      if (newVal) {
        this._getAuthorityList()
      }
    }
  },
  methods: {
    updateSetting () {
      const { id } = this.role
      const selected = this.$refs.tree
        .getCheckedNodes(false, true)
        .map((item) => item.id)
      let selectedId
      if (selected.length) {
        const selectAll = !this.$refs.tree.getHalfCheckedKeys().includes(1)
        if (selectAll) {
          selectedId = 1
        } else {
          selected.shift()
          selectedId = selected.join()
        }
      } else {
        selectedId = -1
      }
      setRoleAuth(id, selectedId).then(() => {
        this.$message.success('角色权限分配成功')
        this.$emit('close')
      })
    },
    closeDialog () {
      this.$emit('close')
    },
    _getAuthorityList () {
      this.isShow = true
      this.loading = true
      getAuth().then((res) => {
        this.authorityList = res
        const { id } = this.role
        getRoleAuth(id)
          .then((res) => {
            this.loading = false
            this.$refs.tree.setCheckedKeys(res)
            setTimeout(() => {
              this.isShow = false
            }, 360)
          })
          .catch(() => {
            this.loading = false
          })
      })
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss">
.setting-wrapper {
  transition: all 0.3s linear;
  background-color: #fff;
  width: 400px;
  border-left: 1px solid #eaeaea;
  position: fixed;
  right: -320px;
  top: 61px;
  bottom: 10px;
  z-index: 99;
  opacity: 0;
  overflow: scroll;
  padding: 60px 0;

  .srcoll-cont {
    position: absolute;
    top: 60px;
    bottom: 60px;
    left: 0;
    width: 100%;
    overflow: auto;
  }
}

.fix-footer {
  // background-color: #f1f1f1;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fix;
  bottom: 0;
  z-index: 100;
}

.slide-left {
  opacity: 1;
  right: 0;
}

.slide-right {
  opacity: 1;
  right: -320px;
}
</style>
