<template>
  <div class="role-page">
    <p class="header-text">{{ selectRole ? selectRole.name : "未选择" }}</p>
    <div style="margin: 20px 0">
      <el-button round type="primary" @click="handleEdit(null)"
        >新增角色</el-button
      >
      <!-- <el-button round type='primary' @click='isAddCharactor = true'>新增角色</el-button> -->
      <el-button round @click="isSetAuth = true">设置权限</el-button>
      <el-button round type="primary" plain @click="handleEdit(selectRole)"
        >编辑</el-button
      >
      <el-button
        round
        type="danger"
        plain
        @click="handleDelete(selectRole)"
        :disabled="selectRole && selectRole.id === 1"
        >删除角色
      </el-button>
    </div>
    <div style="margin: 20px 0">
      <i class="iconfont icontuanduirenyuan label-icon"></i>
      <span class="header-text">角色人员</span>
    </div>
    <div class="flex-between">
      <div>
        <el-button
          type="primary"
          icon="el-icon-plus"
          :disabled="!selectRole"
          @click="dialogVisible = true"
          >新增用户
        </el-button>
        <el-button
         class='custom-button-margin-left'
          type="danger"
          :disabled="selected.length === 0"
          @click="handleUserDelete(selected)"
          >批量删除</el-button
        >
      </div>
      <div style="flex: 1; text-align: right">
        <el-input
          placeholder="用户名"
          v-model="searchName"
          @keydown.enter.native="getUser"
          style="width: 200px; margin-right: 20px"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getUser"
          ></el-button>
        </el-input>
        <el-input
          placeholder="手机号"
          v-model="searchPhone"
          @keydown.enter.native="getUser"
          style="width: 200px"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getUser"
          ></el-button>
        </el-input>
      </div>
    </div>
    <el-table
      v-loading="userLoading"

      @selection-change="(row) => (this.selected = row.map((item) => item.id))"
      :data="roleUserList"
      height="calc(100vh - 400px)"
      style="width: 100%; margin-top: 20px"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="adminUserId" label="用户ID" width="180">
      </el-table-column>
      <el-table-column prop="name" label="姓名"> </el-table-column>
      <el-table-column prop="mobile" label="手机号"> </el-table-column>
      <el-table-column prop="action" width="200px" label="操作">
        <template slot-scope="scope">
          <el-button
           class='button-small'
            type="primary"

            @click="
              roleDialog = true;
              selectUserRow = scope.row.id;
            "
            >移动到</el-button
          >
          <el-button
          class='button-small'
            type="danger"

            @click="handleUserDelete([scope.row.id])"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-drawer title="新增角色" size="500px" :visible.sync="isAddCharactor">
      <el-input
        placeholder="请输入角色名称"
        v-model="newCharactorName"
      ></el-input>
      <div class="button-operation">
        <el-button @click="(isAddCharactor = false), (newCharactorName = '')"
          >取消</el-button
        >
        <el-button type="primary" @click="handleOk">确认</el-button>
      </div>
    </el-drawer>
    <el-drawer title="添加新用户" :visible.sync="dialogVisible" size="500px">
      <div style="height: 300px">
        <el-select
          v-model="selectUser"
          style="width: 100%"
          placeholder="请选择"
          filterable
          multiple
        >
          <el-option
            v-for="item in selectUserList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleUserAdd">确 定</el-button>
      </div>
    </el-drawer>
    <!-- <select-dept-user
    :show='roleDialog'
    :multiple='true'
    :onlyDept="true"
    @close='roleDialog = false'
    @confirm="handleConfirm"
     ></select-dept-user> -->
    <!-- <el-dialog
      title="选择角色"
      :visible.sync="roleDialog"
      width="500px"
      :before-close="handleRoleClose"
    > -->
    <el-drawer
      title="选择角色"
      size="500px"
      :visible.sync="roleDialog"
      :append-to-body="true"
    >
      <div style="height: 300px">
        <el-select
          v-model="moveRole"
          style="width: 90%"
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="item in roleList"
            :disabled="selectRole.id === item.id"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="dialog-footer">
        <el-button @click="handleRoleClose">取 消</el-button>
        <el-button type="primary" @click="handleUserMove">确 定</el-button>
      </div>
    </el-drawer>
    <set-auth :show="isSetAuth" :role="selectRole" @close="isSetAuth = false" />
  </div>
</template>

<script>
import {
  editRole,
  deleteRole,
  getRoleUser,
  deleteRowUser,
  setRoleUser,
  moveUserRole
} from '@/api/role'
import { mapGetters } from 'vuex'
import SetAuth from './auth'
// import SelectDeptUser from './selectDeptUser'

export default {
  name: 'role',
  props: {
    roleList: Array,
    selectRole: Object
  },
  data () {
    return {
      roleUserList: [],
      userLoading: false,
      selected: [],
      dialogVisible: false,
      selectUser: [],
      roleDialog: false,
      moveRole: null,
      selectUserRow: null,
      isSetAuth: false,
      searchName: null,
      searchPhone: null,
      isAddCharactor: false, // 新增角色
      newCharactorName: '' // 新增角色名称
    }
  },
  watch: {
    selectRole (newVal) {
      if (newVal) {
        //  获取用户列表
        this.getUser()
      }
    }
  },
  computed: {
    selectUserList () {
      const { userList, roleUserList } = this
      return userList.filter(
        (item) => !roleUserList.some((user) => user.adminUserId === item.id)
      )
    },
    ...mapGetters(['userList'])
  },
  methods: {
    handleUserMove () {
      moveUserRole(this.selectUserRow, this.moveRole).then(() => {
        this.$message.success('移动成功')
        this.getUser()
        this.handleRoleClose()
      })
    },
    handleOk () {},
    handleClose () {
      this.dialogVisible = false
      this.selectUser = []
    },
    handleRoleClose () {
      this.roleDialog = false
      this.moveRole = null
      this.selectUserRow = null
    },
    handleUserAdd () {
      const { selectUser } = this
      if (selectUser.length) {
        setRoleUser(this.selectRole.id, selectUser).then(() => {
          this.$message.success('新增成功')
          this.getUser()
          // this.getRoles()
          this.handleClose()
        })
      } else {
        this.$message.warning('请选择用户')
      }
    },
    handleUserDelete (data) {
      this.$confirm('此操作将移除角色中选中用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteRowUser(data.join()).then(() => {
            this.$message.success('刪除成功')
            this.getUser()
          })
        })
        .catch(() => {})
    },
    getUser () {
      if (this.selectRole) {
        this.userLoading = true
        getRoleUser(this.selectRole.id, {
          name: this.searchName,
          mobile: this.searchPhone
        })
          .then((res) => {
            this.roleUserList = res
          })
          .finally(() => {
            this.userLoading = false
          })
      }
    },
    handleEdit (data) {
      this.$prompt('请输入角色名', data ? '编辑' : '新增', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: data ? data.name : ''
      })
        .then(({ value }) => {
          if (value) {
            const params = { name: value }
            if (data) params.id = data.id
            editRole(params).then(() => {
              this.$message.success('保存成功')
              this.$emit('refreshRoles')
              // this.getRoles()
            })
          } else {
            this.$message.warning('操作失败！请输入角色名')
          }
        })
        .catch(() => {})
    },
    handleCommand (command, item) {
      switch (command) {
        case 'edit':
          this.handleEdit(item)
          break
        case 'delete':
          this.handleDelete(item)
          break
      }
    },
    handleDelete (item) {
      this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteRole(item.id).then(() => {
            this.$message.success('删除成功')
            // this.getRoles()
            this.$emit('refreshRoles')
          })
        })
        .catch(() => {})
    },
    handleConfirm () {}
  },
  components: {
    SetAuth
    // SelectDeptUser
  }
}
</script>

<style lang='scss' scoped>
/deep/ .el-drawer {
  padding: 20px;
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
/deep/ .el-drawer {
  padding: 0 20px;
}
.role-page {
  height: 100%;
}

.header-text {
  font-size: 18px;
  font-weight: bold;
}

.label-icon {
  margin-right: 10px;
  color: #1c9399;
  font-size: 22px;
}
.dialog-footer {
  margin-top: 40px;
  text-align: center;
}
.button-operation {
  margin-top: 40px;
  text-align: center;
}
</style>
