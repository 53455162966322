<template>
  <div class="team-page">
    <p class="header-text">{{selectTeam ? selectTeam.name + `(${selectTeam.totalMan || 0 }人)` :"未选择"}}</p>
    <div style="margin: 20px 0">
      <el-button round @click="isEditUser=true" :disabled="selectTeam &&selectTeam.id===1">设置</el-button>
      <el-button round type='primary' plain @click='isAddSubTeam=true'>新增子团队</el-button>
      <el-button round type="danger" plain @click="handleDeleteTeam"
                 :disabled="selectTeam && selectTeam.id===1">删除团队
      </el-button>
    </div>
    <div style="margin: 20px 0">
      <i class="iconfont icontuanduirenyuan label-icon"></i>
      <span class="header-text">团队人员</span>
    </div>
    <div class="flex-between">
      <div>
        <el-button  type="primary" icon="el-icon-plus" @click="isAddUser=true">新增用户</el-button>
        <el-button class='custom-button-margin-left' type="primary" :disabled="selected.length===0" @click="selectUser=selected;isMoveUser=true">批量移动
        </el-button>
        <el-button class='custom-button-margin-left' type="danger" :disabled="selected.length===0" @click="handleDelete(selected)">批量删除</el-button>
      </div>
      <div style="flex: 1;text-align: right">
        <el-input placeholder="用户名" v-model="searchName" @keydown.enter.native="getUsers" style="width: 200px">
          <el-button slot="append" icon="el-icon-search" @click="getUsers"></el-button>
        </el-input>
        <el-input class='custom-button-margin-left' placeholder="手机号" v-model="searchPhone" @keydown.enter.native="getUsers"
                  style="width: 200px">
          <el-button slot="append" icon="el-icon-search" @click="getUsers"></el-button>
        </el-input>
      </div>
    </div>
    <el-table

        v-loading="loading"
        height="calc(100vh - 396px)"
        :data="teamUserList"
        @selection-change="(row)=>this.selected=row.map(item=>item.id)"
        style="width:100%;margin-top: 20px">
      <el-table-column
          type="selection"
          width="55">
      </el-table-column>
      <el-table-column
          prop="adminUserId"
          label="用户ID"
          width="80">
      </el-table-column>
      <el-table-column
          prop="name"
          label="用户信息">
        <template slot-scope="scope">
          <span>{{scope.row.name}}</span>
          <el-tag type="danger" v-if="scope.row.deptLeader" style="margin-left: 15px">组长</el-tag>
        </template>
      </el-table-column>
      <el-table-column
          prop="mobile"
          label="手机号">
      </el-table-column>
      <el-table-column
          prop="deptName"
          label="所在部门">
      </el-table-column>
      <el-table-column
          prop="action"
          width="200px"
          label="操作">
        <template slot-scope="scope">
          <el-button class='button-small' type="primary" @click="selectUser=[scope.row.id];isMoveUser=true">移动到</el-button>
          <el-button class='button-small' type="danger" @click="handleDelete([scope.row.id])">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-drawer
     title='新增用户'
     direction='rtl'
     size='500px'
     :visible.sync='isAddUser'
    >
    <add-user
        :show="isAddUser"
        :team="selectTeam"
        :team-user="teamUserList"
        @close="isAddUser=false"
        @refresh="getUsers"
    />
    </el-drawer>
    <el-drawer
     title='团队设置'
     direction='rtl'
     size='450px'
     :visible.sync='isEditUser'
    >
    <edit-user
        :show="isEditUser"
        :team="selectTeam"
        :team-user="teamUserList"
        @close="isEditUser=false"
        @refresh="getUsers"
    />
    </el-drawer>
    <el-drawer
     title='移动到'
     direction='rtl'
     size='500px'
     :visible.sync='isMoveUser'
    >
    <transform
        :show="isMoveUser"
        :user="selectUser"
        :team-list="teamList"
        @close="isMoveUser=false"
        @refresh="getUsers"
    />
    </el-drawer>
    <el-drawer title='新增子团队'
               size='400px'
               direction='rtl'
               :visible.sync='isAddSubTeam'
    >
     <div class='add-sub-team'>
       <el-input v-model='subTeamName' placeholder="请输入团队名称" style='width:80%;padding-left:50px'></el-input>
       <div class='button-operation'>
         <el-button plain @click="(subTeamName='',isAddSubTeam=false)">取消</el-button>
         <el-button type='primary' @click='handleOk'>确认</el-button>
       </div>
     </div>
    </el-drawer>
  </div>
</template>

<script>
import AddUser from './team/addUser'
import EditUser from './team/edit'
import Transform from './team/transform'
import { addTeam, getTeamUserList, deleteTeam, removeTeamUser } from '@/api/team'

export default {
  name: 'Team',
  props: {
    selectTeam: Object,
    teamList: Array
  },
  data () {
    return {
      loading: false,
      selected: [],
      teamUserList: [],
      isAddUser: false,
      isEditUser: false,
      isMoveUser: false,
      selectUser: null, // 选中的操作用户
      searchName: null,
      searchPhone: null,
      isAddSubTeam: false,
      subTeamName: '' // 子团队名称
    }
  },
  watch: {
    selectTeam (newVal) {
      if (newVal) {
        this.getUsers()
      }
    }
  },
  methods: {
    getUsers () {
      if (this.selectTeam) {
        this.loading = true
        getTeamUserList(this.selectTeam.id, {
          name: this.searchName,
          mobile: this.searchPhone
        }).then(res => {
          this.teamUserList = res
        }).finally(() => { this.loading = false })
      }
    },
    handleDeleteTeam () {
      this.$confirm('此操作将永久删除该团队, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteTeam(this.selectTeam.id).then(() => {
          this.$message.success('删除成功')
          this.selectTeam = null
          this.getTeam()
        })
      }).catch(() => {
      })
    },
    handleDelete (selected) {
      this.$confirm('此操作将删除部门下选中用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        removeTeamUser(selected.join()).then(() => {
          this.getUsers()
          this.$message.success('删除成功')
        })
      }).catch(() => {
      })
    },
    handleTeamClick (node) {
      this.selectTeam = node
    },
    handleOk () {
      if (!this.subTeamName) {
        this.$message({
          showClose: true,
          message: '请输入团队名称！',
          type: 'error'
        })
        return
      }
      addTeam({
        name: this.subTeamName,
        parentId: this.selectTeam.id
      }).then(() => {
        this.getTeam()
      }).finally(() => {
        this.isAddSubTeam = false
      })
    },
    handleEdit () {
      this.$prompt('请输入团队名称', '新增子团队', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        if (value) {
          addTeam({
            name: value,
            parentId: this.selectTeam.id
          }).then(() => {
            this.getTeam()
          })
        } else {
          this.$message.warning('操作失败！请输入团队名')
        }
      }).catch(() => {
      })
    }
  },
  components: {
    AddUser,
    EditUser,
    Transform
  }
}
</script>

<style lang='scss' scoped>
/deep/ .el-drawer {
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
  .team-page {
    height: 100%;
  }

  .header-text {
    font-size: 18px;
    font-weight: bold;
  }

  .label-icon {
    margin-right: 10px;
    color: #1c9399;
    font-size: 22px;
  }
  .button-operation{
    text-align: center;
    margin-top: 40px;
  }

</style>
