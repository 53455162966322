<template>
  <div class="organize-team-transform">
    <div style="height: 200px">
      <!-- <el-cascader
        style="width: 90%;padding-left:50px"
        :props="treeProps"
        v-model="selectTeam"
        :options="teamList"
      ></el-cascader> -->
      <el-input
        type="textarea"
        v-model="branchName"
        placeholder="请选择团队"
        style="width:90%;padding-left:50px"
        @focus="isShow=true"
      ></el-input>
    </div>
    <div class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitLoading"
        >确 定</el-button
      >
    </div>
    <select-dept-user
      :onlyDept="true"
      :multiple="false"
      :show="isShow"
      @close='isShow=false'
      @confirm='handleConfirm'
    ></select-dept-user>
  </div>
</template>

<script>
import { moveUser } from '@/api/team'
import SelectDeptUser from './../selectDeptUser'

export default {
  name: 'transform',
  props: {
    show: Boolean,
    user: Array,
    teamList: Array
  },
  data () {
    return {
      selectTeam: null,
      treeProps: {
        checkStrictly: true,
        emitPath: false,
        value: 'id',
        label: 'name'
      },
      submitLoading: false,
      branchName: '', // 选择的团队
      isShow: false
    }
  },
  methods: {
    handleClose () {
      this.selectTeam = null
      this.$emit('close')
    },
    handleSubmit () {
      if (!this.selectTeam) {
        this.$message.warning('请选择团队')
        return false
      }
      this.submitLoading = true
      moveUser(this.user.join(), this.selectTeam)
        .then(() => {
          this.$emit('refresh')
          this.handleClose()
          this.$message.success('调整成功')
        })
        .finally(() => {
          this.submitLoading = false
        })
    },
    handleConfirm (selected) {
      this.branchName = selected.name
      this.selectTeam = selected.id
    }
  },
  components: {
    SelectDeptUser
  }
}
</script>

<style scoped>
.dialog-footer {
  margin-top: 40px;
  text-align: center;
}
</style>
